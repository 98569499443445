import React from "react"
import styled from "styled-components"

const CrewCard = ({ text, image, name }) => {
  return (
    <MainWrapper>
      <Content>
        <Picture src={image} loading="lazy" alt="testimonial" />
        <NameTitleDiv>
          <Name>{name}</Name>
          <CustomerTitle>{text}</CustomerTitle>
        </NameTitleDiv>
      </Content>
    </MainWrapper>
  )
}

export default CrewCard

const Picture = styled.img`
  flex-grow: 1;
  background-color: #f0f0f0;
  border-radius: 13px 13px 0 0;
`
const MainWrapper = styled.div`
  width: 280px;
  border: 1px solid #f0f0f0;
  border-radius: 13px;
  color: #fff;
  display: flex;
  flex-direction: column;

  @media (min-width: 424px) {
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const Logo = styled.div``

const Image = styled.div`
  display: flex;
  height: 50px;
  max-height: 50px;
`

const Text = styled.span`
  line-height: 1.4;
  justify-self: flex-start;
  margin-top: 0.5rem;
  white-space: normal;
  font-size: 0.9rem;

  @media (min-width: 424px) {
    line-height: 1.6;
    font-size: 0.98rem;
  }
`

const Footer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding-top: 1.5rem;
  margin-top: auto;
`

const NameTitleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  padding: 1.6rem;
`

const Name = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  font-family: InterBold;
`

const CustomerTitle = styled.span`
  color: ${({ theme }) => theme.colors.fontBlack};
  font-family: Inter;
`
