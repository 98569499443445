import React from "react"
import Layout from "../components/Layout"
import { CustomThemeProvider } from "../theme/ThemeProvider"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { CustomButton } from "../components/common/Button"
import Seo from "../components/seo"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { FadeIn } from "../components/animations/FadeIn"
import MeetOurCrew from "../components/about/MeetOurCrew"
import Testimonials from "../components/Home/Testimonials"
import SenoSParama from "../../static/sebosparama.jpg"
const About = ({ data }) => {
  const appUrl = process.env.GATSBY_APP_URL
  return (
    <CustomThemeProvider>
      <Seo title="About Robinize" />
      <Header>
        <HeadingContainer>
          <Title>About Robinize</Title>
          <Desc>
            Robinize is an AI-powered SEO tool that helps you research and write
            SEO-optimized content quickly.
          </Desc>
        </HeadingContainer>
        <GatsbyImage
          image={getImage(data.about.childImageSharp)}
          alt="feat"
          style={{
            position: "absolute",
            inset: "0",
            zIndex: "-2",
          }}
        />
      </Header>
      <Layout>
        <Background>
          <MainWrapper>
            <AboutSection>
              <MiddleWrapper>
                <AboutLeft>
                  <AboutHeading center>
                    An AI writing and content editing software made by SEOs for
                    SEOs.
                  </AboutHeading>
                  <AboutDesc>
                    In essence, we've left no stone unturned in our pursuit of
                    success. Robinize is the result of a fusion between
                    AI-powered technology and the cumulative knowledge of SEO
                    strategists, content creators, and software developers.
                  </AboutDesc>
                </AboutLeft>
              </MiddleWrapper>
            </AboutSection>
          </MainWrapper>
        </Background>
        <BottomSection>
          <BottomLeft>
            <AboutHeading>Why did we make this SEO tool?</AboutHeading>
            <AboutDescBottom>
              The writing tools we used were too complicated and offered a lot
              of unnecessary features. We wanted an easy-to-use tool that gives
              first-class recommendations on content research, writing, and
              optimization. So we made one, tested it on our pages skyrocketed
              our rankings. Now we want to share it with you.
            </AboutDescBottom>
            <AboutHeading>
              It does all of that at a reasonable price
            </AboutHeading>
            <AboutDescBottom>
              Quality doesn’t have to be ridiculously expensive. We want to make
              sure that our tool is just as available for startups & freelancers
              as it is for megacorps.
            </AboutDescBottom>
          </BottomLeft>
          <BottomRight>
            <GatsbyImage
              image={getImage(data.arrow.childImageSharp)}
              alt="bow_and_arrow"
            />
          </BottomRight>
        </BottomSection>
        <ButtonDiv>
          <AnchorLink to={`${appUrl}/register`}>
            <Pricing>Try for free</Pricing>
          </AnchorLink>
        </ButtonDiv>
        <VideoSection>
          <VideoContainer>
            <Video
              title={"Robinize"}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              loading="lazy"
              srcDoc={`<style>
                  * {
                    padding:0;
                    margin:0;
                    overflow:hidden;
                  }

                  body, html {
                    height:100%;

                  }
                  img, svg {
                    position:absolute;
                    width:100%;
                    top:0;
                    bottom:0;
                    margin:auto;
                  }

                   body:hover .play {
                    filter: drop-shadow(1px 1px 10px hsla(206.5,0%,10%,0.2));
                    transform: scale(1.02);
                   }

                   .play {
                     bottom:-10%;
                     left:18%;
                     height:30%;
                     filter: drop-shadow(1px 1px 10px hsla(206.5,70.7%,10%,0.2));
                     transition: all 250ms ease-in-out;
                   }

                  </style>
                  <a href='https://www.youtube.com/embed/zb_svgYYh9U?autoplay=1'>
                  <img alt='senad_pic' src='${SenoSParama}'/>
                  <img alt='video_svg' class='play' src='./playbutton.svg'>
                  </a>
                  `}
            />
          </VideoContainer>
          <div>
            <AboutHeading>SEO Experience</AboutHeading>
            <AboutDescBottom>
              We combined expertise and knowledge about SEO and AI content
              creation with ZenDev's first-class developers, dedicated
              engineers, and creative designers. The founded of Robinize, Senad
              Šantić has over 15 years of experience in the SEO world and is a
              serial entrepreneur. During his student days, with no money for
              marketing, he was forced to learn SEO to rank his web pages.
            </AboutDescBottom>
            <AboutDescBottom>
              And since we like to show it with examples, here is a video where
              he explained how he made $100,000+ in Passive Income with a
              website. He also shared the methodology he used, so you can use it
              in your projects.
            </AboutDescBottom>
          </div>
        </VideoSection>
        <MeetOurCrew />
        <Footer>
          <FooterDesc>Our team loves it, and we hope you will, too!</FooterDesc>
          <StyledLink
            to="https://app.robinize.com/register"
            color={"white"}
            aria-label="Register"
          >
            Try it now
          </StyledLink>
        </Footer>
      </Layout>
    </CustomThemeProvider>
  )
}

export default About

const StyledLink = styled(Link)`
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.headlineWhite};
  cursor: pointer;
  overflow: hidden;
  border-radius: 4px;

  padding: 12px 25px;
  font-size: 0.9rem;
  font-family: ManropeBold;
  background-color: ${({ theme }) => theme.colors.primary};
`

const FooterDesc = styled.h3`
  color: ${({ theme }) => theme.colors.fontBlack};
  font-weight: bold;
  font-family: Inter500;
  @media (max-width: 1334px) {
    text-align: center;
  }
`
const Footer = styled.div`
  box-sizing: border-box;
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
  }
`
const VideoContainer = styled.div`
  position: relative;
  min-height: 300px;
  min-width: 500px;

  overflow: hidden;
  border-radius: 15px;
  @media (max-width: 1334px) {
    aspect-ratio: 16 / 9;
    min-width: 90%;
    max-width: 90%;
    flex-direction: column;
  }
`

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
`
const VideoSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100px;
  padding: 2rem 0px 20px 0px;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (max-width: 840px) {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
`
const Background = styled.div`
  background-color: #fff;
`

const MainWrapper = styled.div`
  padding: 2rem 0px 1rem 0px;
  max-width: 1280px;
  margin: 0 auto;
`

const HeadingContainer = styled.div`
  max-width: 1280px;
  align-self: center;
  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const Header = styled.section`
  margin-top: 4rem;
  align-items: start;
  background: linear-gradient(
    91deg,
    #212121,
    rgba(26, 26, 26, 0.78) 62%,
    transparent
  );
  display: flex;
  justify-content: center;
  height: 500px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: clamp(2rem, 5.5vw, 3.75rem);
  font-family: Inter500;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.primary};
`

const Desc = styled.span`
  font-family: Inter;
  font-size: clamp(1.1rem, 2.5vw, 1.6rem);
  line-height: 1.4;
  max-width: 850px;
  color: #fff;
  width: 80%;

  @media (min-width: 952px) {
    width: 100%;
  }
`

const AboutSection = styled.section`
  display: flex;
  flex-direction: column;
`

const AboutTitle = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 0;
  font-family: Inter500;
  color: #fff;
`

const AboutLeft = styled.div`
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const AboutHeading = styled.h2`
  color: ${({ theme }) => theme.colors.primary};
  font-size: clamp(1.3rem, 1.8vw, 1.67rem);
  line-height: 1.2;
  margin-bottom: 1.1rem;
  text-align: ${({ center }) => (center ? "center" : "left")};
  font-family: InterBold;
  @media (max-width: 1334px) {
    text-align: left;
  }
`

const AboutDesc = styled.p`
  font-family: Inter500;
  font-size: clamp(0.9rem, 1.5vw, 1.2rem);
  letter-spacing: 0.15px;
  line-height: 1.6;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.fontWhite};
  text-align: center;
  width: 100%;
  @media (max-width: 1334px) {
    text-align: left;
  }
`

const AboutDescBottom = styled(AboutDesc)`
  text-align: left;
  color: ${({ theme }) => theme.colors.fontWhiteContent};
`
const MiddleWrapper = styled.div`
  display: flex;
  margin-bottom: 3rem;
  flex-direction: column;

  @media (min-width: 1400px) {
    flex-direction: row;
  }
`
const BottomSection = styled.section`
  display: flex;
  align-items: center;
  padding: 2rem 0px 20px 0px;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const BottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;

  @media (min-width: 840px) {
    width: 60%;
  }
`

const BottomRight = styled.div`
  width: 30%;
  margin-left: auto;
  display: none;

  @media (min-width: 840px) {
    display: block;
  }
`
const ButtonDiv = styled.div`
  padding: 0 0px 4rem 0px;
  max-width: 1280px;
  margin: 0 auto;

  @media (max-width: 1334px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const Pricing = styled(CustomButton)`
  padding: 15px 50px 15px 50px;
  font-family: Inter500;
  font-size: 1.125rem;
  background-color: ${({ theme }) => theme.colors.accent};
  transition: all 0.3s ease-out;
  will-change: filter;
  border-radius: 8px;

  &:hover {
    filter: brightness(1.1);
  }
`

export const query = graphql`
  query Images {
    zendev: file(relativePath: { eq: "zendev.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    alviral: file(relativePath: { eq: "alviral.webp" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    arrow: file(relativePath: { eq: "target&arrow.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    about: file(relativePath: { eq: "about-us.webp" }) {
      childImageSharp {
        gatsbyImageData(webpOptions: { quality: 100 }, placeholder: BLURRED)
      }
    }
    seno: file(relativePath: { eq: "sebo.jpg" }) {
      childImageSharp {
        gatsbyImageData(webpOptions: { quality: 100 }, placeholder: BLURRED)
      }
    }
  }
`
