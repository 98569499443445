import React, { useRef, useState } from "react"
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import RightArrow from "../../assets/right-arrow.svg"
import LeftArrow from "../../assets/left-arrow.svg"
import styled from "styled-components"
import "swiper/css"
import CrewCard from "../common/CrewCard"
import SenoPic from "../../../static/sebo.png"
import AmarPic from "../../../static/amarCEO.png"
import ValerijaPic from "../../../static/valery.png"

const ourTeamArray = [
  {
    name: "Senad Santic",
    text: "Founder",
    image: SenoPic,
  },
  {
    name: "Amar Handzo",
    text: "CEO",
    image: AmarPic,
  },
  {
    name: "Valerija Eres",
    text: "Marketing specialist",
    image: ValerijaPic,
  },
]

const MeetOurCrew = () => {
  const swiperRef = useRef()
  const renderTestimonials = ourTeamArray.map((item, index) => (
    <SwiperSlide key={item.title}>
      <CrewCard text={item.text} image={item.image} name={item.name} />
    </SwiperSlide>
  ))

  const [isFirstSlide, setIsFirstSlide] = useState(true)
  const [isLastSlide, setIsLastSlide] = useState(false)

  return (
    <MainWrapper>
      <CenterDiv>
        <Title>Meet our team</Title>
        <Description>
          Robinize is a product under ZenDev umbrella. ZenDev is a software
          development and digital marketing agency, and we first created
          Robinize for ourselves, tested it and want to tell all SEO content
          writers about it!
        </Description>
      </CenterDiv>
      <div style={{ position: "relative" }}>
        <Swiper
          modules={[Navigation, Pagination]}
          style={{ maxWidth: "1250px", width: "100%" }}
          slidesPerView={1}
          spaceBetween={20}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          grabCursor="true"
          effect={"fade"}
          className="swiper-container"
          freeMode={true}
          breakpoints={{
            840: {
              slidesPerView: 2,
            },
            1240: {
              slidesPerView: 3,
            },
          }}
          scrollbar={{ draggable: true }}
          onSwiper={swiper => {
            swiperRef.current = swiper
          }}
          onReachBeginning={() => {
            setIsFirstSlide(true)
          }}
          onReachEnd={() => {
            setIsLastSlide(true)
          }}
        >
          {renderTestimonials}
        </Swiper>
        <SwiperArrow
          isLastSlide={isLastSlide}
          disabled={isLastSlide}
          onClick={e => {
            e.preventDefault()
            setIsFirstSlide(false)
            swiperRef.current.slideNext()
          }}
          aria-label="Swiper-arrow-right"
        >
          <RightArrow />
        </SwiperArrow>
        <SwiperArrowLeft
          isFirstSlide={isFirstSlide}
          disabled={isFirstSlide}
          onClick={e => {
            e.preventDefault()
            setIsLastSlide(false)
            swiperRef.current.slidePrev()
          }}
          aria-label="Swiper-arrow-left"
        >
          <LeftArrow />
        </SwiperArrowLeft>
      </div>
    </MainWrapper>
  )
}

export default MeetOurCrew

const SwiperArrow = styled.button`
  background: none;
  border: none;
  width: ${({ isLastSlide }) => (isLastSlide ? "40px" : "50px")};
  transform: scale(1);
  position: absolute;
  color: white;
  bottom: 0;
  right: -2.8rem;
  top: 43%;
  z-index: 30;
  cursor: pointer;
  opacity: ${({ isLastSlide }) => (isLastSlide ? "0.2" : "0.8")};
  height: 40px;
  transition: width 0.4s ease-in-out;

  @media (min-width: 560px) {
    right: -3%;
  }

  @media (min-width: 840px) {
    right: -5rem;
  }

  @media (min-width: 1734px) {
    right: -1rem;
  }
  &:disabled {
    pointer-events: none;
  }
`
const SwiperArrowLeft = styled(SwiperArrow)`
  right: 0;
  left: -2.8rem;
  opacity: ${({ isFirstSlide }) => (isFirstSlide ? "0.2" : "0.8")};
  width: ${({ isFirstSlide }) => (isFirstSlide ? "40px" : "50px")};
  @media (min-width: 560px) {
    left: -3%;
  }

  @media (min-width: 840px) {
    left: -5rem;
  }

  @media (min-width: 1734px) {
    left: -1rem;
  }

  &:disabled {
    pointer-events: none;
  }
`

const MainWrapper = styled.div`
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  overflow: hidden;
  background-color: #fff;
  @media (min-width: 1024px) {
    padding-top: 120px;
  }
`

const CenterDiv = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 5rem;
  @media (max-width: 1334px) {
    align-items: flex-start;
    padding: 0px 10%;
  }
`

const Title = styled.span`
  font-size: 2rem;
  font-family: InterBold;
  position: relative;
  color: ${({ theme }) => theme.colors.primary};
  &:before {
    content: "";
    opacity: 0;
    bottom: 10%;
    left: 0;
    right: 0;
    width: inherit;
    height: 30%;
    background-color: ${({ theme }) => theme.colors.primary};
    z-index: -1;
    position: absolute;
  }
  @media (max-width: 840px) {
    align-items: flex-start;
    text-align: left;
  }
`
const Description = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.colors.fontBlack};
  width: 60%;
  @media (max-width: 840px) {
    align-items: flex-start;
    width: 90%;
    text-align: left;
    white-space:
    padding-left: 0;
  }
`
